import React, { useState, forwardRef, useCallback, useEffect } from 'react';
import DatabaseTable from './DatabaseTable.js';
import { secureSessionStore, secureSessionRetrieve, secureSessionDelete } from '../api/secureStore.js';
import './Dashboard.css';


export function DataContainer({ isDarkMode, isMobile, Ampersandposium, reloadData, showDelete, userEmail, userKey, AIKeyVal, setAIKeyVal, wait, styles, apiVisible, edit, del, json }, ref) {;
  const [displayValue, setDisplayValue] = useState('');

  const handleKeytrieve = async () => {
    const aiKey = await secureSessionRetrieve(userEmail);
    return aiKey
  };
  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    setAIKeyVal(inputValue);
    setDisplayValue('*'.repeat(inputValue.length));
  };

  const handleAPISave = async () => {
    await secureSessionStore(userEmail, AIKeyVal);
  };

  const handleAPIDelete = async () => {
    setAIKeyVal('');
    setDisplayValue('');
    await secureSessionDelete(userEmail);
  };

  useEffect(() => {
    const fetchAIKey = async () => {
      try {
        const aiKey = await handleKeytrieve();
        if (aiKey) {
          setAIKeyVal(aiKey);
          setDisplayValue('*'.repeat(aiKey.length));
        }
      } catch (error) {
        console.error('Error fetching AI key:', error);
      }
    };
  
    fetchAIKey();
  }, []);
  
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: "20px"  }}>
            {apiVisible &&
              <>
                <h3 style={{ ...styles.responseContainerH3, marginTop: "2px", marginBottom: "0" }}>OpenAI API:</h3>
                
                <input 
                    type="text"
                    placeholder="Your OpenAI API key here"
                    value={displayValue}
                    onChange={handleInputChange}
                    style={{ ...styles.apiInput }} 
                />

              {!isMobile && 
                <>
                  <button style={{...styles.btn, marginLeft: "-8px"}} onClick={() => handleAPISave()}>Save</button>
                  <button style={{...styles.btn, marginLeft: "-5px"}} onClick={() => handleAPIDelete()}>Delete</button>
                </>
              }
            </>
            }
            {/* Spacer element */}
            <div style={{ flexGrow: 1 }}></div>

          </div>
        </div>
          {apiVisible &&
              <>
                {isMobile &&
                  <>
                  <button style={{...styles.btn, marginLeft: "-10px", marginTop: '-10px' }} onClick={() => handleAPISave()}>Save</button>
                  <button style={{...styles.btn, marginLeft: "-5px", marginTop: '-10px' }} onClick={() => handleAPIDelete()}>Delete</button>
                  </>
                }
              </>
            }
            <div style={{ marginTop: '-50px' }}></div>
        <DatabaseTable Ampersandposium={Ampersandposium} reloadData={reloadData} showDelete={showDelete} userEmail={userEmail} userKey={userKey} AIKeyVal={AIKeyVal} isDarkMode={isDarkMode} wait={wait} styles={styles} edit={edit} del={del} json={json} />
      </div>
    </>
  );
}

export default forwardRef(DataContainer);