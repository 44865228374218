/* global Plotly */
import React, { useState, useEffect, useCallback } from 'react';
import Modal from './RoodModal';
import './Dashboard.css'; 
import styled from 'styled-components';
import { setCookie, getCookie, removeCookie } from '../cookieUtils';
import VectorVault from 'vectorvault';
import { Settings, Download, Upload } from 'lucide-react';

// Styled Components
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; // Ensures that your column widths are respected

  th, td {
    padding: 10px;
    border: 1px solid ${props => props.isDarkMode ? '#333' : '#e0e0e0'};
    text-align: center;
    vertical-align: middle;
    overflow: hidden; // Hide overflow content
    text-overflow: ellipsis; // Show ellipsis for overflow text
    white-space: nowrap; // Keep the content in a single line
    color: ${props => props.isDarkMode ? '#ddd' : '#333'}; // Light text for dark mode
  }

  tr:nth-child(even) {
    background-color: ${props => props.isDarkMode ? '#3d424e' : '#f7f7f7'};
  }

  tr:hover {
    background-color: ${props => props.isDarkMode ? '#58688b' : '#eef6fc'};
  }

  th:nth-child(1), td:nth-child(1) {
    width: ${props => props.edit ? '10%' : '5%'}; // Assigning width for the 'Item' column
  }

  th:nth-child(2), td:nth-child(2) {
    // Styles specific to 'Created' column
    width: 15%; // Assigning width for the 'Created' column
    padding-top: 7px;
    padding-bottom: 5px;
    white-space: normal; // This will allow the text to wrap
    word-wrap: break-word; // To ensure that words break properly
  }

  th:nth-child(3), td:nth-child(3) {
    width: ${props => props.edit ? '60%' : '95%'}; // Assigning width for the 'Data' column
  }

  th:nth-child(4), td:nth-child(4) {
    width: 8%; // Assigning width for the 'Delete' column
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    th, td {
      padding: 5px; // Reduced padding
      font-size: 14px; // Reduced font size
    }

    th:nth-child(1), td:nth-child(1) {
      width: 10%; 
    }
  
    th:nth-child(2), td:nth-child(2) {
        display: none; // Hide on smaller screens
    }
  
    th:nth-child(4), td:nth-child(4) {
      width: 10%; 
    }
  }
`;

const StyledTableContainer = styled.div`
    padding: 20px;

    @media (max-width: 768px) {
    padding: 0px; // Reduced padding for mobile view
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const PaginationButton = styled.button`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
    margin-right: 5px; // Less margin on mobile
  }
`;

const PageIndicator = styled.span`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
  }
`;

const JumpToInput = styled.input`
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
  }
`;

const PaginationSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
    margin-top: 10px; // Add space between sections on mobile
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

const SearchButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
`;

const GearIconContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${props => props.isDarkMode ? '#333' : '#fff'};
  border: 1px solid ${props => props.isDarkMode ? '#555' : '#ccc'};
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1000;
  font-size: 0.8rem;
`;

const DropdownItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${props => props.isDarkMode ? '#444' : '#f0f0f0'};
  }
`;


const transformData = (incomingData) => {
    if (!incomingData || !Array.isArray(incomingData)) return [];
    return incomingData.map(item => ({
        id: item.metadata.item_id,
        name: item.metadata.name,
        created: item.metadata && (item.metadata.created || item.metadata.created_at) 
                 ? (formatTimestamp(item.metadata.created) || formatTimestamp(item.metadata.created_at)).split('T')[0] 
                 : '',
        data: item.data
    }));
};

const fetchItems = async ( itemIds, Ampersandposium, userEmail, userKey ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      const items = await vectorVault.getItems(itemIds)
      return transformData(items); // Access the 'results' key and return transformed data
    } catch (error) {
        console.error("There was an error fetching the data:", error);
        throw error; // We re-throw the error so it can be caught and handled by the caller
    }
};

const fetchTotalItems = async ( Ampersandposium, userEmail, userKey ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey)
      const totalItems = await vectorVault.getTotalItems()
      return totalItems
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

const fetchSearchResults = async ( searchQuery, Ampersandposium, userEmail, userKey, AIKeyVal ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal)
      const results = await vectorVault.getSimilar({ text: searchQuery })
      return transformData(results.results); // Assuming the response JSON directly contains the total items count
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

const deleteItem = async ( itemId, Ampersandposium, userEmail, userKey ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      await vectorVault.deleteItems(itemId)
      return true; 
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

function formatTimestamp(timestamp) {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  return date.toLocaleString('en-US', options);
}

// Main Component
function DataTable({ Ampersandposium, reloadData, userEmail, userKey, AIKeyVal, isDarkMode, wait, styles, edit, del, json }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState('');
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentCreatedDate, setCurrentCreatedDate] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [name, setName] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [databaseData, setDatabaseData] = useState([])
  const [currentPage, setCurrentPage] = useState(!isNaN(parseInt(getCookie('currentPage'))) ? parseInt(getCookie('currentPage')) : 1);
  const [totalItems, setTotalItems] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [desiredPage, setDesiredPage] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchData = useCallback(async () => {
    if (wait) return;  // Ensure no fetches occur if waiting is necessary.

    try {
      const total = await fetchTotalItems(Ampersandposium, userEmail, userKey);
      setTotalItems(total);
      setItemsPerPage(total < 10 ? total : 10);

      const startId = (currentPage - 1) * itemsPerPage;
      const endId = Math.min(startId + itemsPerPage, total);
      const currentPageItemIds = Array.from({ length: endId - startId }, (_, i) => startId + i);

      const dataItems = await fetchItems(currentPageItemIds, Ampersandposium, userEmail, userKey);
      setDatabaseData(dataItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsDataLoading(false);
    }
  }, [Ampersandposium, userEmail, userKey, currentPage, itemsPerPage, wait]);

  // Consolidate data fetching logic
  
  useEffect(() => {
    if (!Ampersandposium || wait) return;
    if (!userKey) return;
    fetchData();
  }, [fetchData]);
  
  useEffect(() => {
    if (totalItems === 0) {
      setIsDataLoading(false)
    }}, [totalItems])

  const handlePage = (newPage) => {
    setCookie('currentPage', newPage)
    setCurrentPage(newPage);
  };

  const handleDataClick = (item) => {
      setCurrentItemId(item.id);
      setCurrentCreatedDate(item.created);
      setName(item.name);
      setCurrentData(item.data);
      setIsModalOpen(true);
  };

  const handleSearch = async () => {
    try {
      setIsDataLoading(true);
      const searchResults = await fetchSearchResults(searchQuery, Ampersandposium, userEmail, userKey, AIKeyVal);
      setDatabaseData(searchResults);
      removeCookie('currentPage')
      setIsDataLoading(false);
    } catch (error) {
      console.error("Error searching for:", searchQuery, error);
    }
  };      

  const handleDelete = async (itemId) => {
    // Ask the user to confirm the deletion
    if (window.confirm("Are you sure you want to delete this item?")) {
        try {
            setIsDataLoading(true);
            await deleteItem(itemId, Ampersandposium, userEmail, userKey);
            setCurrentPage(prevPage => prevPage);
            setIsDataLoading(false);
            alert("Item deleted.");
            window.location.reload();
        } catch (error) {
            console.error(`Error deleting item with ID: ${itemId}`, error);
        }
    } else {
        // User clicked 'Cancel', so do nothing
        console.log('Deletion cancelled by the user.');
    }
  };

  const handleGearClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDownloadJSON = async () => {
    try {
      setIsDataLoading(true);
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      const result = await vectorVault.downloadToJson({
        item_ids: databaseData.map(item => item.id) // Assuming each item has an 'id' field
      });
      setIsDataLoading(false);
      
      const dataStr = JSON.stringify(result);
      const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
      const exportFileDefaultName = `vectorvault_${Ampersandposium}_data.json`;
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    } catch (error) {
      console.error('Error downloading JSON:', error);
      alert('Failed to download JSON. Please try again.');
    } finally {
      setIsDropdownOpen(false);
    }
  };

  const handleUploadJSON = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/json';
    input.onchange = async (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        try {
          const jsonData = JSON.parse(content);
          const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
          setIsDataLoading(true);
          await vectorVault.uploadFromJson(jsonData);
          setIsDataLoading(false);
          alert('JSON data uploaded successfully');
          // Refresh the data after upload
          fetchData();
        } catch (error) {
          console.error('Error uploading JSON:', error);
          alert('Failed to upload JSON. Please ensure the file is valid.');
        }
      };
      reader.readAsText(file);
    };
    input.click();
    setIsDropdownOpen(false);
  };

  return (
      <StyledTableContainer>
          {isDataLoading ? (
              // Render the loading spinner when isDataLoading is true
              <div className="loading-spinner" style={{ display: 'block' }}>
                  <svg viewBox="0 0 50 50">
                      <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                  </svg>
              </div>
          ) : (
              <>
          <h2 style={{marginTop: "40px"}}>
          Database: <span>{totalItems} items</span>
                <SearchContainer>
                  <SearchInputContainer>
                    <input
                      className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                      type="text"
                      placeholder="Search the database with a question or statement..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{ marginLeft: '0px', marginTop: '6px', width: '100%', fontSize: "1rem", padding: '10px' }}
                    />
                  </SearchInputContainer>
                  <SearchButtonContainer>
                    <button onClick={handleSearch} style={{...styles.button}}>Search</button>
                    {json && 
                      <GearIconContainer>
                        <Settings
                          onClick={handleGearClick}
                          style={{ cursor: 'pointer', color: isDarkMode ? '#fff' : '#333' }}
                        />
                        {isDropdownOpen && (
                          <DropdownMenu isDarkMode={isDarkMode}>
                            <DropdownItem onClick={handleDownloadJSON} isDarkMode={isDarkMode}>
                              <Download size={30} style={{ marginRight: '5px' }} />
                              Download Database to JSON
                            </DropdownItem>
                            <DropdownItem onClick={handleUploadJSON} isDarkMode={isDarkMode}>
                              <Upload size={30} style={{ marginRight: '5px' }} />
                              Replace Database from JSON
                            </DropdownItem>
                          </DropdownMenu>
                        )}
                      </GearIconContainer>
                    }
                  </SearchButtonContainer>
                </SearchContainer>
          </h2>
          <StyledTable isDarkMode={isDarkMode} edit={edit} >
              <thead>
                  <tr>
                      <th>Item</th>
                      {edit && <th>Created</th>}
                      <th>Data</th>
                      {del && <th>Delete</th>}
                  </tr>
              </thead>
              <tbody>
                  {databaseData.map((item, index) => (
                      item ? (
                          <tr key={index}>
                              <td>{item.id}</td>
                              {edit && <td>{item.created}</td>}
                              <td style={{ textAlign: 'left' }}>
                                  <span onClick={() => handleDataClick(item)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                      {item.data && item.data.length > 50 ? item.data.substring(0, 130) + "..." : item.data}
                                  </span>
                              </td>
                                  {del && (
                                      <td>
                                          <span 
                                              onClick={() => handleDelete(item.id)}
                                              style={{ cursor: 'pointer', color: 'red' }}
                                          >
                                              🗑️
                                          </span>
                                      </td>
                                  )}
                          </tr>
                      ) : null
                  ))}
              </tbody>
          </StyledTable>
          <Modal 
              isOpen={isModalOpen} 
              onClose={() => setIsModalOpen(false)} 
              itemId={currentItemId} 
              createdDate={currentCreatedDate} 
              name={name} 
              content={currentData} 
              Ampersandposium={Ampersandposium} 
              isDarkMode={isDarkMode}
              userEmail={userEmail}
              userKey={userKey}
              AIKeyVal={AIKeyVal}
              styles={styles}
              edit={edit}
          />
          
          {/* Pagination controls */}
          <PaginationContainer>
          <div>
              <PaginationButton 
              className={ isDarkMode ? 'btn-dark' : 'btn-light' }
              style={{ marginLeft: "0px" }}
              onClick={() => handlePage(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
              >
              Previous
              </PaginationButton>
              <PageIndicator>
              Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
              </PageIndicator>
              <PaginationButton 
              className={ isDarkMode ? 'btn-dark' : 'btn-light' }
              style={{ marginLeft: "0px" }}
              onClick={() => handlePage(Math.min(currentPage + 1, Math.ceil(totalItems / itemsPerPage)))}
              disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
              >
              Next
              </PaginationButton>
          </div>
          <PaginationSection>
              <a style={{ marginTop: '0px' }}>Page: </a>
              <JumpToInput 
              className={ isDarkMode ? 'sleek-input-dark' : 'sleek-input-light' }
              style={{ marginTop:"-0px", maxWidth: "75px" }} 
              type="number" 
              value={desiredPage}
              onChange={e => setDesiredPage(Number(e.target.value))}
              />
              <PaginationButton 
              className={ isDarkMode ? 'btn-dark' : 'btn-light' }
              style={{ marginTop:"2px", marginRight: "0px" }} 
              onClick={() => handlePage(Math.max(1, Math.min(desiredPage, Math.ceil(totalItems / itemsPerPage))))}
              >
              Go
              </PaginationButton>
          </PaginationSection>
          </PaginationContainer>

  
          {/* Bottom of Page Spacing */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          </>
      )}

      </StyledTableContainer>
  );
}


export default DataTable;
