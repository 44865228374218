// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Dashboard from './dashboard/Dashboard'; 
import { postMail } from './api/credationStation';
import useTagManager from './hooks/useTagManager';
import { darkStyles, lightStyles } from './styles.js';
import Database from './dashboard/Database.js';

function App() {
  const userEmail = window.env.USER;
  const [title, setTitle] = useState('');
  const [Ampersandposium, setAmpersandposium] = useState('');
  const [AIKeyVal, setAIKeyVal] = useState('');
  const [userKey, setUserKey] = useState(false);
  const [wait, setWait] = useState(true); 
  const [deployments, setDeployments] = useState({});

  useTagManager();

  useEffect(() => {
      document.title = title;
  }, [title]);

  const handleCredGet = useCallback(async () => {
    try {
      setWait(true)
      const result = await postMail(userEmail);
      setUserKey(result.key);
      setDeployments(result.deployments);
      setTitle(result.deployments.TITLE)
      setWait(false)
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  }, [userEmail, setUserKey]);
  
  useEffect(() => {
    if (userEmail) {
      handleCredGet();
    }
  }, [userEmail, handleCredGet]); 

  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <BrowserRouter>
        <div className="Content"> 
          <Routes>
            {Object.entries(deployments).map(([key, deployment]) => (
              <Route
                key={deployment.FORWARDSLASH}
                path={`/${deployment.FORWARDSLASH}`}
                element={
                  deployment.CHAT ? (
                    <Dashboard 
                      Ampersandposium={deployment.VAULT}
                      setAmpersandposium={setAmpersandposium}
                      userEmail={userEmail}
                      userKey={userKey}
                      isDarkMode={deployment.DARK}
                      styles={deployment.DARK ? darkStyles : lightStyles}
                      AIKeyVal={AIKeyVal}
                      setAIKeyVal={setAIKeyVal}
                      wait={wait}
                      data={deployment.DATA}
                      edit={deployment.EDIT}
                      del={deployment.DELETE}
                      gp4={deployment.GPT4}
                      apiVisible={deployment.APIVISIBLE}
                      change={deployment.CHANGE}
                      context={deployment.CONTEXT}
                      description={deployment.DESCRIPTION}
                      title={deployment.TITLE}
                      json={deployment.JSON}
                    />
                  ) : (
                    <Database
                      Ampersandposium={deployment.VAULT}
                      setAmpersandposium={setAmpersandposium}
                      userEmail={userEmail}
                      userKey={userKey}
                      isDarkMode={deployment.DARK}
                      styles={deployment.DARK ? darkStyles : lightStyles}
                      AIKeyVal={AIKeyVal}
                      setAIKeyVal={setAIKeyVal}
                      wait={wait}
                      edit={deployment.EDIT}
                      del={deployment.DELETE}
                      apiVisible={deployment.APIVISIBLE}
                      description={deployment.DESCRIPTION}
                      title={deployment.TITLE}
                      json={deployment.JSON}
                    />
                  )
                }
              />
            ))}
          </Routes>
        </div>
      </BrowserRouter> 
      {/* <p>
        <a href="https://vectorvault.io" target="_blank" rel="noopener noreferrer">
          Powered by Vector Vault
        </a>
      </p> */}
    </div> 
  );
}

export default App;
